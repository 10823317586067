import React from 'react';
import { GiCompass, GiDiamondHard, GiStabbedNote } from 'react-icons/gi';
import {
  AiFillFacebook,
  AiFillInstagram,
} from 'react-icons/ai';

const TikTokIcon = ({ color = "#000000" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="20%"
      height="50%"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};
export const links = [


  {
    id: 3,
    text: 'products',
    url: '/products',
  },
];

export const socialLinks = [
  {
    id: 1,
    icon: (
      <AiFillInstagram
        className='instagram'
        color='var(--clr-white)'
        fontSize='2.5rem'
      />
    ),
    text: 'Instagram',
    url: 'https://www.instagram.com/badgers.iq?igsh=MXRuNjVvN2ZxMWxwZg%3D%3D&utm_source=qr',
  },
  {
    id: 2,
    icon: (
      <div style={{ width: "200px" }}>
      <TikTokIcon className='instagram'
      color='var(--clr-white)'
      fontSize='2.5rem' />
    </div>
    ),
    text: 'TikTok',
    url: 'https://www.tiktok.com/@badgers.iq?_t=8nFjeUo9q2a&_r=1',
  },
  {
    id: 3,
    icon: (
      <AiFillFacebook
        className='facebook'
        color='var(--clr-white)'
        fontSize='2.5rem'
      />
    ),
    text: 'Facebook',
    url: 'https://www.facebook.com/profile.php?id=61558954811694&mibextid=LQQJ4d',
  },

];

export const footerLinks = [
  {
    id: 1,
    text: 'Home',
    url: '/',
  },

  {
    id: 3,
    text: 'Products',
    url: '/products',
  },

  {
    id: 5,
    text: 'Orders',
    url: '/orders',
  },
];

export const services = [
  {
    id: 1,
    icon: <GiCompass />,
    title: 'mission',
    text: 'Our mission is to provide our customers the best in class products and services at a very reasonable price.',
  },
  {
    id: 2,
    icon: <GiDiamondHard />,
    title: 'vision',
    text: 'Our vision is to take Badgers to greater heights, by providing our customers best in class service.',
  },
  {
    id: 3,
    icon: <GiStabbedNote />,
    title: 'history',
    text: `Badgers was started in December'21 with an initial aim to provide the best in class services to our customers.`,
  },
];

const domain = process.env.REACT_APP_BACKEND_HOST;
export const products_url = `${domain}/api/products`;
export const single_product_url = `${domain}/api/products/`;
export const create_order_url = `${domain}/api/orders/new`;
export const get_order_url = `${domain}/api/orders`;
export const payment_url = `${domain}/api/payment/create-payment-intent`;
export const upload_url = `${domain}/api/upload/`;
export const default_profile_image =
  'https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg';
