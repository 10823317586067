import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useProductsContext } from '../../context/products_context';
import { single_product_url as url } from '../../utils/constants';
import { formatPrice } from '../../utils/helpers';
import CryptoJS from 'crypto-js';

import {
  Loading,
  Error,
  ProductImages,
  AddToCart,
  Stars,
  PageHero,
  ReviewModal,
  UserReview,
} from '../../components';
import Wrapper from './styles';
import { Link } from 'react-router-dom';

const SingleProductPage = () => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const [promoCodeEntered, setPromoCodeEntered] = useState('');
  const [isValid, setIsValid] = useState(null);

  const handleInputChange = (event) => {
    setPromoCodeEntered(event.target.value);
  };
  const { id } = useParams();
  const history = useHistory();
  let {
    single_product_loading: loading,
    single_product_error: error,
    single_product: product,
    fetchSingleProduct,
  } = useProductsContext();

  useEffect(() => {
    fetchSingleProduct(`${url}${id}`);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        history.push('/');
      }, 3000);
    }
    // eslint-disable-next-line
  }, [error]);

  let {
    name,
    price,
    description,
    stock,
    rating: stars,
    numberOfReviews,
    reviews = [],
    _id: sku,
    images,
    promoPercent,
    promo,
  } = product;
   const [discount , setDiscount] = useState(null)
  const handleVerify = () => {
    let encryptedPromoCode = promo;
    let bytes = CryptoJS.AES.decrypt(encryptedPromoCode, secretKey);
    let decryptedPromoCode = bytes.toString(CryptoJS.enc.Utf8);
    if (decryptedPromoCode.toString() === promoCodeEntered.toString()) {
      setIsValid(true);
      setDiscount(promoPercent/100)
    } else {
      setDiscount(null)
      setIsValid(false);
    }
  };
  useEffect(() => {
    document.title = `Badgers Wear | ${name}`;
  }, [name]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Wrapper>
      <PageHero title={name} product />
      <div className='section section-center page'>
        <Link to='/products' className='btn'>
          back to products
        </Link>
        <div className='product-center'>
          <ProductImages images={images} />
          <section className='content'>
            <h2>{name}</h2>
            <Stars stars={stars} reviews={numberOfReviews} />
            <h5 className='price'>{formatPrice(discount ? (price - (price * discount)) : price)}</h5>
            <p className='desc'>{description}</p>
            <p className='info'>
              <span>Available : </span>
              {stock > 0 ? 'In stock' : 'Out of stock'}
            </p>
            <p className='info'>
              <span>SKU : </span>
              {sku}
            </p>
            <div>
              <input
                className='search-input'
                type='text'
                value={promoCodeEntered}
                onChange={handleInputChange}
                placeholder='Enter promo code'
              />
              <button className='btn' onClick={handleVerify}>
                Verify
              </button>
              {isValid === true && <p style={{ color: 'green' }}>Promo code Applied!</p>}
              {isValid === false && <p style={{ color: 'red' }}>Promo code is invalid!</p>}
            </div>
            <hr />
            {stock > 0 && (
              <AddToCart className='cart-buttons' product={{...product, price: discount ? (price - (price * discount)) : price}} />
            )}
            <hr />
            <ReviewModal product={{...product, price: discount ? (price - (price * discount)): price}} />
            <section className='reviews'>
              <h3>Reviews</h3>
              {reviews.length < 1 && (
                <p>No reviews yet, be the first one to review &#128512;</p>
              )}
              {reviews.map((review, index) => {
                return <UserReview key={index} {...review} />;
              })}
            </section>
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

export default SingleProductPage;
