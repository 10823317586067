import React from 'react';
import { Wrapper, Main } from './styles';
import { Link } from 'react-router-dom';
import heroBcg from '../../assets/hero-bcg.jpeg';
import heroBcg2 from '../../assets/hero-bcg-2.jpeg';
import heroBcg3 from '../../assets/main.jpeg';

const Hero = () => {
  return (
    <div>
      <Main>
        <div className='main-img-frame'>
          <img
            src={heroBcg3}
            alt=''
            style={{
              width: '100%',
              height: '100%',
              borderRadius: 'var(--radius)',
            }}
          />
          <Link to='/products' className='center-button'>
            shop now
          </Link>
        </div>
      </Main>

      <Wrapper className='section-center'>
        <article className='content'>
          <h1>simply be exceptional</h1>

          <Link to='/products' className='btn'>
            shop now
          </Link>
        </article>
        <article className='img-container'>
          <img src={heroBcg} alt='' className='main-img' />
          <img src={heroBcg2} alt='' className='accent-img' />
        </article>
      </Wrapper>
    </div>
  );
};

export default Hero;
