import styled from 'styled-components';

export const Wrapper = styled.section`
  min-height: 60vh;
  display: grid;
  place-items: center;
  .img-container {
    display: none;
  }

  p {
    line-height: 2;
    max-width: 45em;
    margin-bottom: 2rem;
    color: var(--clr-grey-5);
    font-size: 1rem;
  }
  @media (min-width: 992px) {
    height: calc(10vh - 5rem);
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    h1 {
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.25rem;
    }
    .hero-btn {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
    }
    .img-container {
      display: block;
      position: relative;
    }
    .main-img {
      width: 100%;
      height: 550px;
      position: relative;
      border-radius: var(--radius);
      display: block;
      object-fit: cover;
    }
    .accent-img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 250px;
      transform: translateX(-50%);
      border-radius: var(--radius);
    }
    .img-container::before {
      content: '';
      position: absolute;
      width: 10%;
      height: 80%;
      background: var(--clr-primary-9);
      bottom: 0%;
      left: -8%;
      border-radius: var(--radius);
    }
  }
`;
export const Main = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .main-img-frame {
    height: 1050px;
    width: 100%;
    border-radius: var(--radius);
    position: relative; // Ensure this container is the reference for absolute positioning
    display: block;
  }

  .center-button {
   position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 20px;
    font-size: 1.5rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.1); /* semi-transparent black background */
    border: 2px solid white; /* white border */
    border-radius: 5px; /* slight rounding of corners */
    text-decoration: none; /* remove underline from link */
  }
  .center-button:hover {
  color: var(--clr-primary-2);
  background: var(--clr-primary-8);
}
  @media (pointer:coarse){
    .main-img-frame {
      height: 450px;
    }
    .center-button {
      padding: 5px 10px;
      font-size: 1rem;
    }


}
`